import React from "react";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import useHotjarAnalytics from "../hooks/useHotjarAnalytics";
import useCustomAnalytics from "./../hooks/useCustomAnalytics";
import useHeapAnalytics from "./../hooks/useHeapAnalytics";

export const AnalyticsContext = React.createContext({});

export const AnalyticsProvider = ({ children }) => {
  const [
    newVisit,
    sendEvent,
    sendPageExitEvent,
    sendPageVisibleEvent,
    sendPageHiddenEvent,
  ] = useCustomAnalytics();

  const [HeapComponent, sendHeapEvent] = useHeapAnalytics();

  const [initGA, trackPage] = useGoogleAnalytics();

  const [initHotjar] = useHotjarAnalytics();

  return (
    <AnalyticsContext.Provider
      value={{
        customAnalytics: {
          newVisit,
          sendEvent,
          sendPageExitEvent,
          sendPageVisibleEvent,
          sendPageHiddenEvent,
        },
        heapAnalytics: {
          HeapComponent,
          sendHeapEvent,
        },
        googleAnalytics: {
          initGA,
          trackPage,
        },
        hotjarAnalytics: {
          initHotjar,
        },
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
