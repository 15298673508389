import React, { useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { AnalyticsContext } from "../contexts/AnalyticsContext";

function Header({ navLinks }) {
  const { customAnalytics } = useContext(AnalyticsContext);
  const { sendEvent } = customAnalytics;
  return (
    <header className="desktop-header-3 fixed-top">
      <div className="container">
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Navbar.Toggle className="ml-auto border-white" />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav as="ul" className="ml-auto scrollspy">
              {navLinks.data.map((link) => {
                return (
                  <Nav.Item as="li" key={link.id}>
                    <Nav.Link
                      {...navLinks.linkProps}
                      eventKey={link.id}
                      as={navLinks.Link}
                      to={link.to}
                      onClick={(evt) => {
                        sendEvent({
                          action: "click",
                          target: {
                            text: evt.target.innerText,
                            tagName: evt.target.tagName,
                            className: evt.target.className,
                          },
                        });
                      }}
                      onSetActive={(activeTo) => {
                        sendEvent({
                          action: "nav.scroll.setActive",
                          to: activeTo,
                        });
                      }}
                    >
                      {link.display}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
}

export default Header;
