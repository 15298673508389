import { Link as ScrollLink } from "react-scroll";
import { Link as GatsbyLink } from "gatsby";

export const navLinks = {
  Link: ScrollLink,
  linkProps: {
    activeClass: "active-scroll",
    spy: true,
    smooth: true,
    duration: 500,
  },
  data: [
    {
      id: 1,
      to: "section-home",
      display: "Home",
    },
    {
      id: 2,
      to: "section-about",
      display: "About",
    },
    {
      id: 3,
      to: "section-media-coverage",
      display: "Media Coverage",
    },
    {
      id: 4,
      to: "section-experiences",
      display: "Highlights",
    },
    {
      id: 5,
      to: "section-research",
      display: "Research",
    },
    {
      id: 6,
      to: "section-entrepreneurship",
      display: "Entrepreneurship",
    },
    {
      id: 7,
      to: "section-contact",
      display: "Contact",
    },
  ],
};

export const navLinks404 = {
  Link: GatsbyLink,
  linkProps: {},
  data: [
    {
      id: 1,
      to: "/",
      display: "Home",
    },
  ],
};
