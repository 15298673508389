import configs from "../../config/envConfig";
import { hotjar } from "react-hotjar";

const useHotjarAnalytics = () => {
  const initHotjar = () => {
    hotjar.initialize(configs.HOTJAR_TRACKING_ID, configs.HOTJAR_VERSION_ID);
  };
  return [initHotjar];
};

export default useHotjarAnalytics;
