import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="copyright">
          &copy;2016-{new Date().getFullYear()} Mingyuan Li.
        </div>
        <div className="copyright">All rights reserved.</div>
      </div>
    </footer>
  );
}

export default Footer;
