import React, { useEffect, useRef, useContext } from "react";
import {
  AnalyticsContext,
  AnalyticsProvider,
} from "../../contexts/AnalyticsContext";

const withAnalytics = (WrappedComponent) => {
  const getScrollPercent = () => {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  };

  const HOC = (props) => {
    const scrollTwentyFive = useRef(false);
    const scrollFifty = useRef(false);
    const scrollSeventyFive = useRef(false);
    const scrollOneHundred = useRef(false);
    const {
      googleAnalytics,
      customAnalytics,
      heapAnalytics,
      hotjarAnalytics,
    } = useContext(AnalyticsContext);
    const {
      newVisit,
      sendEvent,
      sendPageExitEvent,
      sendPageVisibleEvent,
      sendPageHiddenEvent,
    } = customAnalytics;
    const { HeapComponent, sendHeapEvent } = heapAnalytics;
    const { initGA, trackPage } = googleAnalytics;
    const { initHotjar } = hotjarAnalytics;

    useEffect(() => {
      if (process.env.NODE_ENV === "development") {
        //disable tracking in dev
        console.log("disable tracking in dev");
        return;
      }

      initGA();
      trackPage(props.location.pathname);
      newVisit();
      initHotjar();

      const scrollHandler = () => {
        const scrollPercent = getScrollPercent();

        if (scrollPercent >= 25 && !scrollTwentyFive.current) {
          sendEvent({ scrolldepth: "25%" });
          sendHeapEvent("scroll depth", { percent: 25 });
          scrollTwentyFive.current = true;
        }

        if (scrollPercent >= 50 && !scrollFifty.current) {
          sendEvent({ scrolldepth: "50%" });
          sendHeapEvent("scroll depth", { percent: 50 });
          scrollFifty.current = true;
        }

        if (scrollPercent >= 75 && !scrollSeventyFive.current) {
          sendEvent({ scrolldepth: "75%" });
          sendHeapEvent("scroll depth", { percent: 75 });
          scrollSeventyFive.current = true;
        }

        if (scrollPercent >= 90 && !scrollOneHundred.current) {
          sendEvent({ scrolldepth: "100%" });
          sendHeapEvent("scroll depth", { percent: 100 });
          scrollOneHundred.current = true;
        }
      };

      document.addEventListener("scroll", scrollHandler);

      const beforeUnloadHandler = () => {
        sendPageExitEvent();
        sendHeapEvent("page exit", { exit: true });
        return undefined;
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);

      const visibilitychangeHandler = () => {
        if (document.visibilityState === "hidden") {
          sendPageHiddenEvent();
        }
        if (document.visibilityState === "visible") {
          sendPageVisibleEvent();
        }
      };
      document.addEventListener("visibilitychange", visibilitychangeHandler);

      return () => {
        document.removeEventListener("scroll", scrollHandler);
        window.removeEventListener("beforeunload", beforeUnloadHandler);
        document.removeEventListener(
          "visibilitychange",
          visibilitychangeHandler
        );
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.pathname]);

    return (
      <>
        {process.env.NODE_ENV === "development" ? <></> : <HeapComponent />}
        <WrappedComponent {...props} />
      </>
    );
  };

  const withAnalyticsProvider = (AnalyticsComponent) => {
    const ProviderHOC = (props) => {
      return (
        <AnalyticsProvider>
          <AnalyticsComponent {...props} />
        </AnalyticsProvider>
      );
    };
    return ProviderHOC;
  };

  return withAnalyticsProvider(HOC);
};

export default withAnalytics;
