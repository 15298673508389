import React from "react";
import Header from "./../Header";
import Footer from "./../Footer";

function MainLayout({ navLinks, footer = true, children }) {
  return (
    <>
      <Header navLinks={navLinks} />
      {children}
      {footer ? <Footer /> : <></>}
    </>
  );
}

export default MainLayout;
