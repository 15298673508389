import configs from "../../config/envConfig";
import ReactGA from "react-ga4";

const useGoogleAnalytics = (options = {}) => {
  const initGA = () => {
    ReactGA.initialize(configs.GA_TRACKING_ID);
  };

  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.send({ hitType: "pageview", page: page });
  };

  return [initGA, trackPage];
};

export default useGoogleAnalytics;
